<script setup lang="ts">
const { stationId } = defineProps<{
  stationId: string
}>()

const { websiteCard, donationCard, announcementCard, status } = useStationCards(stationId)

const hasCards = computed(() => websiteCard.value || donationCard.value || announcementCard.value)
</script>

<template>
  <div v-if="hasCards" class="xl:pl-56 pl-0">
    <div v-if="status === 'success'" class="grid md:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
      <div v-if="websiteCard?.enabled">
        <StationCardWebsite :url="websiteCard.url" :label="websiteCard.label" :image="websiteCard.image" />
      </div>

      <div v-if="donationCard?.enabled">
        <StationCardDonation
          :title="donationCard.title"
          :description="donationCard.description"
          :paypal="donationCard.paypal"
          :cashapp="donationCard.cashapp"
        />
      </div>

      <div v-if="announcementCard">
        <StationCardAnnouncement :announcement="announcementCard" />
      </div>
    </div>
  </div>
</template>

<style scoped></style>

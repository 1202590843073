<script lang="ts" setup>
defineProps({
  src: { type: String, required: true },
  alt: { type: String, required: true },
})

const error = ref(false)

const onError = () => {
  error.value = true
}
</script>

<template>
  <div
    :class="[$viewport.isLessThan('md') ? 'w-[120px] h-[120px]' : 'w-[152px] h-[152px]']"
    class="inline-block drop-shadow-[0_6px_10px_rgba(0,0,0,0.04)] p-1 bg-white rounded-full"
  >
    <div class="rounded-full h-full w-full border border-zeno-gray-border-light bg-zeno-gray-light">
      <NuxtImg
        v-if="!error"
        :alt="alt"
        :height="$viewport.isLessThan('md') ? 112 : 144"
        :src="src"
        :width="$viewport.isLessThan('md') ? 112 : 144"
        class="rounded-full border border-zeno-gray-border-light bg-zeno-gray-light"
        sizes="112px md:144px"
        @error="onError"
      />
    </div>
  </div>
</template>

<style scoped></style>

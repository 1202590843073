<script setup lang="ts">
import type { Station } from '~/types/stations'

const { station } = defineProps<{
  station: Station
}>()

const tags = computed(() => {
  const tagList = []

  if (station?.genre) {
    const genreTag = {
      title: station.genre,
      route: { name: 'radio-genres-genre', params: { genre: station.genre } },
    }

    tagList.push(genreTag)
  }

  if (station.country) {
    const countryTag = {
      title: station.country,
      route: { name: 'radio-countries-country', params: { country: station.country } },
    }

    tagList.push(countryTag)
  }

  if (station.languages) {
    const languageTags = station.languages.map((language) => ({
      title: language,
      route: { name: 'radio-languages-language', params: { language: language } },
    }))

    tagList.push(...languageTags)
  }
  return tagList
})
</script>

<template>
  <div v-if="tags.length" class="overflow-x-scroll hidden-scroll">
    <div class="flex md:flex-wrap gap-2">
      <TagLink v-for="tag in tags" :key="tag.title" :route="tag.route">
        {{ tag.title }}
      </TagLink>
    </div>
  </div>
</template>

<style scoped></style>

<script lang="ts" setup>
import type { Station } from '~/types/stations'

const { station } = defineProps<{
  station: Station
}>()

const { data: stations, status } = useRelatedStations(station)

const params = computed(() => {
  return {
    country: station?.country,
    genre: station.genre,
  }
})
</script>

<template>
  <div>
    <MediaListRow
      :items="stations"
      :pending="status === 'pending'"
      :route="{ name: 'radio', query: params }"
      title="Similar Stations"
      type="stations"
    />
  </div>
</template>

<style scoped></style>

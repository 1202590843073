<script setup lang="ts">
import type { Station } from '~/types/stations'
import { usePlayerStore } from '~/stores/usePlayerStore'
import { getTrackingParamsString } from '~/utils/stations'
import { useToast } from '~/components/ui/toast'

const { station } = defineProps<{
  station: Station
}>()

const playerStore = usePlayerStore()
const { toast } = useToast()

const { data: trackingParams } = useNuxtData('tracking-params')

const handlePlay = async () => {
  if (!isCurrent.value) {
    playerStore.isLoading = true

    const allowed = await $fetch(`/api/stations/${station.id}/allowed/`)

    if (!allowed) {
      toast({
        title: 'Station not available',
        description: 'This station is not available in your region.',
        variant: 'destructive',
      })

      playerStore.isLoading = false

      return
    }

    let params

    if (!trackingParams.value) {
      const { data } = await useAsyncData('tracking-params', () => getTrackingParamsString())

      if (data) {
        params = data.value
      }
    } else {
      params = trackingParams.value
    }

    playerStore.media = {
      id: station.id,
      subTitle: station.title,
      logo: station.logo,
      route: station.route,
      source: station.streamURL + (params ? `?${params}` : ''),
      sourceType: station.streamURL.endsWith('.m3u8') ? 'streaming' : 'stream',
      mediaType: 'station',
      streamName: station.streamName,
    }
  }

  playerStore.play()
}

const handlePause = () => {
  playerStore.pause()
}

const isCurrent = computed(() => playerStore.media?.id === station.id)
</script>

<template>
  <Button
    v-if="playerStore.isLoading"
    class="h-12 md:px-0 px-6 md:w-full w-auto rounded-full flex items-center gap-2 text-lg font-semibold"
  >
    <Icon size="28" name="mingcute:loading-3-fill" class="animate-spin" />
    <span>Loading</span>
  </Button>

  <Button
    v-else-if="!playerStore.isPlaying || !isCurrent"
    class="h-12 md:px-0 px-6 md:w-full w-auto rounded-full flex items-center gap-2 text-lg font-semibold"
    @click="handlePlay"
  >
    <Icon size="30" name="ic:round-play-arrow" />
    <span>Listen</span>
  </Button>

  <Button
    v-else-if="playerStore.isPlaying && isCurrent"
    class="h-12 md:px-0 px-6 md:w-full w-auto rounded-full flex items-center gap-2 text-lg font-semibold"
    @click="handlePause"
  >
    <Icon size="30" name="ic:round-pause" />
    <span>Pause</span>
  </Button>
</template>

<style scoped></style>

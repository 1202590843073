<script setup lang="ts">
const {
  url,
  image,
  label = 'Visit Our Website',
} = defineProps<{
  url: string
  image?: string
  label?: string
}>()

let style = ''

if (image) {
  style = `--image-url: url(${image})`
}
</script>

<template>
  <div
    :style="style"
    :class="[image ? `bg-[image:var(--image-url)] bg-center bg-cover` : 'bg-zeno-gray-light']"
    class="min-h-56 p-4 border border-zeno-gray-border-light rounded-2.5xl flex flex-col justify-between"
  >
    <h3 class="flex items-center space-x-2 text-white">
      <Icon size="18" name="ic:round-home" />
      <span class="text-xs uppercase font-semibold"> Website </span>
    </h3>

    <NuxtLink :to="url" class="bg-white block w-full rounded-lg text-center px-4 py-2 truncate">
      {{ label }}
    </NuxtLink>
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
const { stationId } = defineProps<{
  stationId: string
}>()

const { data: podcasts } = useFetch(`/api/stations/${stationId}/podcasts/`)
</script>

<template>
  <div v-if="podcasts && podcasts.length">
    <MediaListRow :items="podcasts" title="Podcasts" type="podcasts" />
  </div>
</template>

<style scoped></style>

<script setup lang="ts">
const { stationId } = defineProps<{
  stationId: string
}>()

const { socialLinks } = useStationCards(stationId)
</script>

<template>
  <div v-if="socialLinks" class="overflow-x-scroll hidden-scroll">
    <div class="social-links flex md:flex-wrap gap-2">
      <NuxtLink v-if="socialLinks.facebook" :to="socialLinks.facebook" target="_blank">
        <Icon size="16" name="bi:facebook" />
        Facebook
      </NuxtLink>

      <NuxtLink v-if="socialLinks.twitter" :to="socialLinks.twitter" target="_blank">
        <Icon size="16" name="bi:twitter-x" />
        Twitter
      </NuxtLink>

      <NuxtLink v-if="socialLinks.instagram" :to="socialLinks.instagram" target="_blank">
        <Icon size="16" name="bi:instagram" />
        Instagram
      </NuxtLink>

      <NuxtLink v-if="socialLinks.snapchat" :to="socialLinks.snapchat" target="_blank">
        <Icon size="16" name="bi:snapchat" />
        Snapchat
      </NuxtLink>

      <NuxtLink v-if="socialLinks.youtube" :to="socialLinks.youtube" target="_blank">
        <Icon size="16" name="bi:youtube" />
        Youtube
      </NuxtLink>

      <NuxtLink v-if="socialLinks.spotify" :to="socialLinks.spotify" target="_blank">
        <Icon size="16" name="bi:spotify" />
        Spotify
      </NuxtLink>

      <NuxtLink v-if="socialLinks.tiktok" :to="socialLinks.tiktok" target="_blank">
        <Icon size="16" name="bi:tiktok" />
        Tiktok
      </NuxtLink>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.social-links a {
  @apply px-4 py-1 bg-zeno-gray-light rounded-full inline-flex items-center sm:text-base text-sm;
}

.social-links svg {
  @apply mr-2;
}
</style>

<script lang="ts" setup>
import IconFeatured from '~/components/icons/IconFeatured.vue'
import IconSponsored from '~/components/icons/IconSponsored.vue'
import { useCookieConsentStore } from '~/stores/useCookieConsentStore'
import { onMounted } from 'vue'

definePageMeta({
  layout: 'radio',
})

const ccStore = useCookieConsentStore()
const { $viewport } = useNuxtApp()
const route = useRoute()

const identifier = useRoute().params.id as string

const { data: station } = await useStation(identifier)

if (!station.value) {
  throw showError({
    statusCode: 404,
    statusMessage: 'Page Not Found',
  })
}

if (station.value.slug !== identifier) {
  navigateTo(station.value.route, { redirectCode: 301 })
}

useSeo({
  title: `Listen to ${station.value.title}`,
  description:
    `Tune in to ${station.value.title} and enjoy the best of ` +
    (station.value.languages.length ? `${station.value.languages.join(', ')} ` : '') +
    `${station.value.genre} radio. Stream live broadcasts, catch up on past episodes, and stay updated with the latest news and announcements.`,
  image: station.value.metaLogo,
})

const ads = [
  {
    country: 'China',
    languages: ['Chinese'],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741184956013-0',
      adUnitPath: '/21869305879/ZenoFM_CHINESE_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1741184864026-0',
      adUnitPath: '/21869305879/ZenoFM_CHINESE_300x250',
    },
  },
  {
    country: 'Vietnam',
    languages: ['Vietnamese'],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741185800486-0',
      adUnitPath: '/21869305879/ZenoFM_VIETNAMESE_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1741185883307-0',
      adUnitPath: '/21869305879/ZenoFM_VIETNAMESE_300x250',
    },
  },
  {
    country: 'Philippines',
    languages: ['Filipino', 'Tagalog'],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741185920384-0',
      adUnitPath: '/21869305879/ZenoFM_FILIPINO_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1741185883307-0',
      adUnitPath: '/21869305879/ZenoFM_FILIPINO_300x250',
    },
  },
  {
    country: 'India',
    languages: ['Indian', 'Hindi', 'English (Indian)'],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741186022644-0',
      adUnitPath: '/21869305879/ZenoFM_INDIAN_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1741185984074-0',
      adUnitPath: '/21869305879/ZenoFM_INDIAN_300x250',
    },
  },
  {
    country: 'Korea South',
    languages: ['Korean'],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741186110525-0',
      adUnitPath: '/21869305879/ZenoFM_KOREAN_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1741186061662-0',
      adUnitPath: '/21869305879/ZenoFM_KOREAN_300x250',
    },
  },
  {
    region: 'Asia',
    languages: [
      'Japanese',
      'Thai',
      'Urdu',
      'Bengali',
      'Burmese',
      'Sinhala',
      'Tamil',
      'Indonesian',
      'Khmer',
      'Punjabi',
      'Malay',
    ],
    topAdUnit: {
      adUnitId: 'div-gpt-ad-1741107126359-0',
      adUnitPath: '/21869305879/ZenoFM_P_ASIA_970x90',
    },
    sideAdUnit: {
      adUnitId: 'div-gpt-ad-1740157903194-0',
      adUnitPath: '/21869305879/ZenoFM_P_ASIA_300x250',
    },
  },
]

const activeAd = computed(() => {
  if (station.value && station.value.adsDisabled) {
    return false
  }

  const ad = ads.find((ad) => {
    if (
      ad.country === station.value?.country ||
      ad.languages.some((lang) =>
        station.value?.languages.some((stationLang) => stationLang.includes(lang) || lang.includes(stationLang))
      )
    ) {
      return ad
    }

    if (ad.region) {
      return ad.region === station.value?.region
    }
  })

  if (!ad) {
    return false
  }

  return ad
})

const showAd = computed(() => {
  if (station.value && station.value.adsDisabled) {
    return false
  }

  return (
    ccStore.isTargetingAccepted &&
    $viewport.isGreaterOrEquals('xl') &&
    (route.name === 'radio-id' || route.name === 'podcast-podcastId') &&
    (route.params.id === 'development' ||
      route.params.id === 'sheil-feb-2021' ||
      route.params.podcastId === 'new-podcast-2-3')
  )
})

onMounted(() => {
  if (station.value) {
    if (station.value.external) {
      window.gtag('event', 'page_view', {
        content_group: 'Third Party Stations',
      })
    } else {
      window.gtag('event', 'page_view', {
        content_group: 'Zeno Stations',
      })
    }
  }
})
</script>

<template>
  <div>
    <div v-if="station" :class="{ 'xl:pr-80': showAd || activeAd }" class="lg:pl-60 pt-8 pb-32 relative">
      <div class="lg:px-8 px-4">
        <div class="max-w-screen-lg mx-auto">
          <div
            v-if="activeAd && $viewport.isGreaterOrEquals('xl') && ccStore.isTargetingAccepted"
            class="flex justify-center mb-8"
          >
            <GoogleAd
              :ad-unit-id="activeAd.topAdUnit.adUnitId"
              :ad-unit-path="activeAd.topAdUnit.adUnitPath"
              :height="90"
              :targeting="{ page_url: route.path }"
              :width="970"
            />
          </div>

          <div class="space-y-12">
            <CoverImage :alt="station.title" :src="station.cover" />

            <div class="flex md:flex-row flex-col md:space-x-8 space-x-0 md:space-y-0 space-y-8">
              <div class="space-y-8 md:pl-8 pl-0 -mt-20 flex-none">
                <div class="text-center">
                  <StationLogo :alt="station.title" :src="station.logo" />
                </div>

                <div class="flex justify-center">
                  <StationPlaybackButton :station="station" />
                </div>
              </div>

              <section class="space-y-5 flex-1">
                <h1 class="md:text-2xl text-lg font-semibold md:text-left text-center flex items-center gap-2">
                  {{ station.title }}

                  <span
                    v-if="station.sponsored"
                    class="p-1 bg-zeno-yellow rounded-full items-center gap-1.5 inline-flex"
                    title="Sponsored"
                  >
                    <IconSponsored />
                    <span class="text-xs text-white pr-2.5">Sponsored</span>
                  </span>

                  <span v-else-if="station.featured" class="inline-flex" title="Featured">
                    <IconFeatured />
                  </span>
                </h1>

                <StationTags :station="station" />

                <LazyStationAdRectangle />

                <div
                  v-if="activeAd && $viewport.isLessThan('xl') && ccStore.isTargetingAccepted"
                  class="flex justify-center"
                >
                  <GoogleAd
                    :ad-unit-id="activeAd.sideAdUnit.adUnitId"
                    :ad-unit-path="activeAd.sideAdUnit.adUnitPath"
                    :height="250"
                    :targeting="{ page_url: route.path }"
                    :width="300"
                  />
                </div>

                <LazyStationAdPrimis v-if="!station.featured && !station.adsDisabled" />

                <DescriptionText v-if="station.description" :text="station.description" />

                <StationSocialLinks :station-id="station.id" />
              </section>
            </div>

            <StationCards :station-id="station.id" />

            <StationPodcasts :station-id="station.id" />

            <StationRelated v-if="!station.featured && !station.adsDisabled" :station="station" />
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="activeAd && $viewport.isGreaterOrEquals('xl') && ccStore.isTargetingAccepted"
      :class="{ 'xl:block': activeAd }"
      class="w-80 fixed right-0 top-1/2 -translate-y-1/2 z-10"
    >
      <GoogleAd
        :ad-unit-id="activeAd.sideAdUnit.adUnitId"
        :ad-unit-path="activeAd.sideAdUnit.adUnitPath"
        :height="250"
        :targeting="{ page_url: route.path }"
        :width="300"
      />
    </div>

    <div v-else-if="showAd" :class="{ 'xl:block': showAd }" class="w-80 fixed right-0 top-1/2 -translate-y-1/2 z-10">
      <GoogleAd
        :height="250"
        :targeting="{ page_url: route.path }"
        :width="300"
        ad-unit-id="div-gpt-ad-1730815690760-0"
        ad-unit-path="/21869305879/ZenoFM_300x250"
      />
    </div>
  </div>
</template>

<style scoped></style>
